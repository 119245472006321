<div class="privacy-policy-container">
    <div class="privacy-policy">
        <h1>{{privacyDataObject.mainHeading.name}} {{companyName}}</h1>
        <div>
            <p>{{privacyDataObject.mainHeading.summary}}. <br/> <small><b>Last Updated: {{lastUpdateDate}}</b></small></p>

            <h2>1. {{privacyDataObject.dataController.name}}</h2>
            <p *ngFor="let entry of privacyDataObject.dataController.value">{{entry}}</p>

            <h2>2. {{privacyDataObject.informationWeCollect.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.informationWeCollect.value"></app-privacy-helper>
            </ul>

            <h2>3. {{privacyDataObject.lawfulBasisForProcessing.name}}</h2>
            <ul>
                <app-privacy-helper
                    [dataValues]="privacyDataObject.lawfulBasisForProcessing.value"></app-privacy-helper>
            </ul>

            <h2>4. {{privacyDataObject.howWeUseYourInformation.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.howWeUseYourInformation.value"></app-privacy-helper>
            </ul>

            <h2>5. {{privacyDataObject.dataSecurity.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.dataSecurity.value"></app-privacy-helper>
            </ul>

            <h2>6. {{privacyDataObject.thirdPartyServices.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.thirdPartyServices.value"></app-privacy-helper>
            </ul>

            <h2>7. {{privacyDataObject.yourRights.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.yourRights.value"></app-privacy-helper>
            </ul>

            <h2>8. {{privacyDataObject.dataTransfers.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.dataTransfers.value"></app-privacy-helper>
            </ul>

            <h2>9. {{privacyDataObject.updatesToPrivacyPolicy.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.updatesToPrivacyPolicy.value"></app-privacy-helper>
            </ul>

            <h2>10. {{privacyDataObject.contactInformation.name}}</h2>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.contactInformation.value"></app-privacy-helper>
            </ul>
        </div>
        <div class="cookie-information-container">
            <h2>{{privacyDataObject.cookieInformation.name}}</h2>
            <p>{{privacyDataObject.cookieInformation.summary}}</p>
            <h3>{{privacyDataObject.typesOfCookieWeUse.name}}</h3>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.typesOfCookieWeUse.value"></app-privacy-helper>
            </ul>
            <h3>{{privacyDataObject.yourCookiesChoice.name}}</h3>
            <ul>
                <app-privacy-helper [dataValues]="privacyDataObject.yourCookiesChoice.value"></app-privacy-helper>
            </ul>
        </div>
    </div>
</div>