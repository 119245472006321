import { Component, OnInit } from '@angular/core';
import { AccountService } from 'amp-ng-library';

import { AMPState } from 'amp-ng-library';
import {Router} from '@angular/router';
import {StateService} from 'amp-ng-library';

@Component({
  selector: 'app-client-tabs',
  templateUrl: './client-tabs.component.html',
  styleUrls: ['./client-tabs.component.scss']
})
export class ClientTabsComponent implements OnInit {

    isClient
    state: AMPState

  constructor(
      private accountService: AccountService,
      private stateService: StateService,
      private router: Router
  ) { }

  ngOnInit(): void {
      this.isClient = this.accountService.isClient() || this.accountService.isUserMode()
      this.state = this.stateService.getCurrentState()
  }

  navigateToSchedules() {
      // this.stateService.setRefresh(true)
      this.router.navigate([`playback/schedules/detail/${this.stateService.getCurrentContentPath()}`], {state: this.state})
  }

  navigateToProgrammes() {
    // this.stateService.setRefresh(true)
      this.router.navigate([`playback/programmes/detail/${this.stateService.getCurrentContentPath()}`], {state: {...this.state}})
  }

  navigateToPlaylists() {
      // this.stateService.setRefresh(true)
      this.router.navigate([`playback/playlists/detail/${this.stateService.getCurrentContentPath()}`], {state: this.state})
  }

  isActive(routePath: string) {
    // const url = `/${this.router.url.split('/')[1]}`
    // return url === routePath;

      const currentRoute = this.router.url;
      return currentRoute.includes(routePath);
  }
}
