import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-index-helper',
  templateUrl: './cookie-index-helper.component.html',
})
export class CookieIndexHelperComponent implements OnInit {

  functionCookie = {
    message: `You may choose to prevent this website from aggregating and analyzing the actions you take here. Doing so
    will protect your privacy, but will also prevent the owner from learning from your actions and creating
    a better experience for you and other users.`,
    name: 'Function Cookies'
  }
  analyticsCookies = {
    name: 'Analytics Cookies',
  }
  allowCookies = 'Allow cookies'
  denyCookies = 'Deny cookies'
  constructor() { }

  ngOnInit(): void {
  }

}
