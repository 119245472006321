import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'amp-ng-library';

import {StateService} from 'amp-ng-library';
import {AppLibraryConfigService} from '../../_services/app-library-config.service';

@Component({
  selector: 'app-dam-tabs',
  templateUrl: './dam-tabs.component.html',
  styleUrls: ['./dam-tabs.component.scss']
})
export class DamTabsComponent implements OnInit {

  isLoading = false;

	constructor(
		private router: Router,
		private configService: AppLibraryConfigService,
    stateService: StateService,
    accountService: AccountService
	) {
		this.configService.configureLibrary(accountService, null, stateService);
	}

  ngOnInit(){
    this.isLoading = true
  }

  isActive(routePath: string) {
    const currentRoute = this.router.url;
    return currentRoute.includes(routePath)
  }

}
