import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

//  this snippet obtain from mat-paginator from angular material
/**
 * @see https://material.angular.io/components/paginator/overview
 */
export declare class PageEvent {
    /** The current page index. */
    pageIndex: number;
    /**
     * Index of the page that was selected previously.
     */
    previousPageIndex?: number;
    /** The current page size, default  to 50 */
    pageSize: number;
    /** The current total number of items being paged */
    length: number;
}

/**
 * pagination configuration
 */
export interface PaginatedConfig { 
	pageSize: number;
	pageIndex: number;
	totalItems: number;
	pageSizeOptions: number[];
	showFirstLastButtons?: boolean;
	selectConfig?: string;
}
@Component({
	selector: 'app-amp-pagination',
	templateUrl: './amp-pagination.component.html',
	styleUrls: ['./amp-pagination.component.scss']
})
export class AmpPaginationComponent implements OnInit, PageEvent {

	isDropdownOpen: boolean = false;

	@Input() length: number;
	@Input() pageSize: number = 50;
	@Input() showFirstLastButtons: boolean;
	@Input() pageIndex: number;
	@Input() pageSizeOptions: number[];

	@Output() pageChanged: EventEmitter<PageEvent> = new EventEmitter();

	constructor() { }

	 
	get totalPages(): number {
		return Math.ceil(this.length / this.pageSize);
	}
	
	private get pageEventParams(): PageEvent {
		return {
			pageIndex: this.pageIndex,
			previousPageIndex: this.pageIndex,
			pageSize: this.pageSize,
			length: this.length
		};
	}

	ngOnInit(): void {
	}

	handlePageChange(event: any): void {
		const pageEvent = {...this.pageEventParams, pageIndex: event.pageIndex};
		this.pageIndex = event.pageIndex;
		this.pageChanged.emit(pageEvent);
	}
	
	handlePageSizeChange(event: any) {
		const pageEvent = {...this.pageEventParams, pageSize: event, pageIndex: 0 };
		this.pageSize = event;
		this.pageChanged.emit(pageEvent);
	}
	
	toggleDropdown() { this.isDropdownOpen = !this.isDropdownOpen; }
	
	clickedOutside(event: any) {
		if (event.visible) {
			this.isDropdownOpen = false;
		}
	 }
}
