import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-privacy-helper",
    templateUrl: "./privacy-helper.component.html",
})
export class PrivacyHelperComponent implements OnInit {
    @Input() dataValues: Array<string>;
    constructor() {}

    ngOnInit(): void {}

    entryContainsHtml(entry: string): boolean {
        let htmlTagRegex = new RegExp(/<(\"[^\"]*\"|'[^']*'|[^'\">])*>/);
        return entry && htmlTagRegex.test(entry);
    }
}
