import { Component, isDevMode, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccountService } from 'amp-ng-library';

import {Country} from 'amp-ng-library'
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { ETrackingMode, ProductFilterService, TrackingService } from 'amp-ng-library';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from './miscellaneous/cookie.service';
import { AppLibraryConfigService } from './_services/app-library-config.service';
import { AlertService } from 'amp-ng-library';
import { RenditionService } from './_services/rendition.service';
import { toggleMemoization } from 'amp-ng-library';
import {StateService} from 'amp-ng-library';
import {AppPlaylistsConfigService} from './_services/app-playlists-config.service';
import {HistoryService, PlaybackDataService} from 'amp-ng-playlists';

declare let _paq: any; // Declare Matomo variable

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    defaultOption: string;
    // GDPR Cookies
    // keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializingSubscription!: Subscription;
    private initializedSubscription!: Subscription;
    private initializationErrorSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;

    public backgroundImage: string = 'assets/img/Login.jpg';
    @ViewChild('cookieDetailContainer', { read: ViewContainerRef, static: false })
    cookieDetailContainer: ViewContainerRef;

    ampLink =
        'https://download365.s3.eu-central-1.amazonaws.com/software/macos/atmosphere365/AMP/Atmosphere-11.0-b1720.dmg';
    atm365 = 'http://www.atmosphere365.com/atmosphere365';
    hospitalityLink = this.atm365;
    spaLink = this.atm365;
    healthcareLink = this.atm365;
    newsLink = this.atm365 + '/news-2';
    isMobile: boolean
    mobileNavBar: boolean = false;
    smallScreen: boolean = false;

    public mediaTypes = [
        { id: 1, label: 'Video Playlist' },
        { id: 2, label: 'Audio Playlist' },
    ];

    // public playlistTypes = [
    //   {id: 1, label: 'Standard Playlist'},
    //     {id: 3, label: 'Advanced Playlist'}
    // ]

    public countries = [];
    private euCountries = {
        AT: 1,
        BE: 1,
        BG: 1,
        HR: 1,
        CY: 1,
        CZ: 1,
        DK: 1,
        EE: 1,
        FI: 1,
        FR: 1,
        DE: 1,
        EL: 1,
        HU: 1,
        IE: 1,
        IT: 1,
        LV: 1,
        LT: 1,
        LU: 1,
        MT: 1,
        NL: 1,
        PL: 1,
        PT: 1,
        RO: 1,
        SK: 1,
        SI: 1,
        ES: 1,
        SE: 1,
        GB: 1,
    };

    constructor(
        private translate: TranslateService,
        private ccService: NgcCookieConsentService,
        public account: AccountService,
        private playbackDataService: PlaybackDataService,
        private cookieService: CookieService,
        private trackingService: TrackingService,
        private productFilterService: ProductFilterService,
        private httpClient: HttpClient,
        private router: Router,
        alertService: AlertService,
        stateService: StateService,
        rendition: RenditionService,
        private historyService: HistoryService,
        private libraryConfigService: AppLibraryConfigService,
        private playlistsConfigService: AppPlaylistsConfigService
    ) {
        this.historyService.subscribe(true);
        this.isMobile = this.libraryConfigService.isMobile();
        toggleMemoization(true);
        this.libraryConfigService.configureLibrary(account, alertService, stateService, rendition, trackingService);
        this.playlistsConfigService.configurePlaylists()

        translate.setDefaultLang('en');
        translate.use(account.getLanguage());

        let trackingMode: ETrackingMode = isDevMode() ? ETrackingMode.DEV : ETrackingMode.PROD;
        // this.trackingService.setTrackingMode(trackingMode); //uncomment this line if you wish to track for dev mode
        trackingService.sendPageViewImmediately();
        trackingService.trackParamChange();

        //ENGLISH
        this.httpClient.get('assets/i18n/en.json').subscribe(data => {
            this.translate.setTranslation('en', data);
        });

        //GERMAN
        this.httpClient.get('assets/i18n/de.json').subscribe(data => {
            this.translate.setTranslation('de', data);
        });

        //FRENCH
        this.httpClient.get('assets/i18n/fr.json').subscribe(data => {
            this.translate.setTranslation('fr', data);
        });

        //SPANISH
        this.httpClient.get('assets/i18n/es.json').subscribe(data => {
            this.translate.setTranslation('es', data);
        });

        //ITALIAN
        this.httpClient.get('assets/i18n/it.json').subscribe(data => {
            this.translate.setTranslation('it', data);
        });

        //POLISH
        this.httpClient.get('assets/i18n/pl.json').subscribe(data => {
            this.translate.setTranslation('pl', data);
        });

        //ARABIC
        this.httpClient.get('assets/i18n/ar.json').subscribe(data => {
            this.translate.setTranslation('ar', data);
        });

        //CHINESE
        this.httpClient.get('assets/i18n/zh.json').subscribe(data => {
            this.translate.setTranslation('zh', data);
        });

        this.translate.get('countries').subscribe((lands: any) => {
            this.countries = lands;
        });
    }

    countryForCode(code) {
        for (var i = 0; i < this.countries.length; i++) {
            var c = this.countries[i];
            if (c.code === code) {
                return c;
            }
        }
        return '';
    }

    isEuCountry(code) {
        return this.euCountries[code];
    }

    setBackgroundImage(imageURL: string) {
        this.backgroundImage = imageURL;
    }

    isAdminAccount(): boolean {
        return this.account != null && this.account.isAdmin();
    }

    colClass(): string {
        if (this.isAdminAccount()) {
            return 'col-10';
        }
        return 'col-8';
    }

    getAccountName(): string {
        return this.account.getAccountName();
    }

    logout() {
        this.playbackDataService.invalidateAll();
        this.productFilterService.resetProducts();
        this.account.logout();
    }

    msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        return hrs + ':' + mins + ':' + secs;
    }

    ngOnInit() {
        this.countries = [
            new Country('AF', 'Afghanistan'),
            new Country('AL', 'Albania'),
            new Country('DZ', 'Algeria'),
            new Country('AR', 'Argentina'),
            new Country('AM', 'Armenia'),
            new Country('AT', 'Austria'),
            new Country('AU', 'Australia'),
            new Country('AZ', 'Azerbaijan'),
            new Country('BE', 'Belgium'),
            new Country('BR', 'Brazil'),
            new Country('BG', 'Bulgaria'),
            new Country('CA', 'Canada'),
            new Country('CN', 'China'),
            new Country('HR', 'Croatia'),
            new Country('CY', 'Cyprus'),
            new Country('CZ', 'Czech Republic'),
            new Country('DE', 'Germany'),
            new Country('DK', 'Denmark'),
            new Country('EG', 'Egypt'),
            new Country('EE', 'Estonia'),
            new Country('FI', 'Finland'),
            new Country('FR', 'France'),
            new Country('HK', 'Hong Kong'),
            new Country('HU', 'Hungary'),
            new Country('IN', 'India'),
            new Country('IE', 'Ireland'),
            new Country('IC', 'Iceland'),
            new Country('IL', 'Israel'),
            new Country('IT', 'Italy'),
            new Country('KW', 'Kuwait'),
            new Country('JP', 'Japan'),
            new Country('LV', 'Latvia'),
            new Country('LT', 'Lithuania'),
            new Country('LU', 'Luxembourg'),
            new Country('MT', 'Malta'),
            new Country('ML', 'Monaco'),
            new Country('MX', 'Mexico'),
            new Country('NL', 'Netherlands'),
            new Country('NZ', 'New Zealand'),
            new Country('NO', 'Norway'),
            new Country('PH', 'Philippines'),
            new Country('PL', 'Poland'),
            new Country('PT', 'Portugal'),
            new Country('QA', 'Qatar'),
            new Country('RO', 'Romania'),
            new Country('RU', 'Russian Federation'),
            new Country('SA', 'Saudi Arabia'),
            new Country('SG', 'Singapore'),
            new Country('SK', 'Slovak Republic'),
            new Country('SI', 'Slovenia'),
            new Country('ZA', 'South Africa'),
            new Country('KR', 'South Korea'),
            new Country('ES', 'Spain'),
            new Country('SE', 'Sweden'),
            new Country('CH', 'Switzerland'),
            new Country('TH', 'Thailand'),
            new Country('TN', 'Tunisia'),
            new Country('TK', 'Turkey'),
            new Country('AE', 'United Arab Emirates'),
            new Country('GB', 'United Kingdom'),
            new Country('US', 'United States of America'),
            new Country('VN', 'Vietnam'),
        ];

        this.trackingService.subscribeToHashChange();
        // this.ccService.init()
        // this.wsService.connect()
        // subscribe to cookieconsent observables to react to main events
        this.cookieService.init();
        this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        /**
         this.initializingSubscription = this.ccService.initializing$.subscribe(
         (event: NgcInitializingEvent) => {
                // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
                console.log(`initializing: ${JSON.stringify(event)}`);
            });

         this.initializedSubscription = this.ccService.initialized$.subscribe(
         () => {
                // the cookieconsent has been successfully initialized.
                // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
                console.log(`initialized: ${JSON.stringify(event)}`);
            });


         this.initializationErrorSubscription = this.ccService.initializationError$.subscribe(
         (event: NgcInitializationErrorEvent) => {
                // the cookieconsent has failed to initialize...
                console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
            });
         **/

        this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
            console.log(event.status);
            if (event.status === 'deny') {
                this.cookieService.rejectCookies();
            } else {
                this.cookieService.acceptCookies();
            }
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
            // you can use this.ccService.getConfig() to do stuff...
        });

        this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
            // you can use this.ccService.getConfig() to do stuff...
        });
    }

    ngAfterViewInit() {
        document.addEventListener('DOMContentLoaded', () => {
            this.cookieService.loadCookieBanner(this.cookieDetailContainer);
            this.cookieService.handleInputCheckBox();
            // if device is mobile add __mobile to body class
            if (this.isMobile) {
                document.body.classList.add('__mobile');
            }
            
            // subscribe to resize if screeen is less 576 then set smallScreen to true
            this.smallScreen = window.innerWidth < 576;
            window.addEventListener('resize', () => {
                if (window.innerWidth < 768) {
                    this.smallScreen = true;
                } else {
                    this.smallScreen = false;
                }
            });
        });
    }

    ngOnDestroy() {
        // unsubscribe to cookieconsent observables to prevent memory leaks
        this.popupOpenSubscription.unsubscribe();
        this.popupCloseSubscription.unsubscribe();
        this.initializingSubscription.unsubscribe();
        this.initializedSubscription.unsubscribe();
        this.initializationErrorSubscription.unsubscribe();
        this.statusChangeSubscription.unsubscribe();
        this.revokeChoiceSubscription.unsubscribe();
        this.noCookieLawSubscription.unsubscribe();
    }

    userModeAvailable(): boolean {
        return this.isAdminAccount() && (this.account.isAdminMode() || this.account.isContentMode());
    }

    contentModeAvailable(): boolean {
        return isDevMode() && this.isAdminAccount() && (this.account.isAdminMode() || this.account.isUserMode());
    }

    adminModeAvailable(): boolean {
        return this.isAdminAccount() && (this.account.isUserMode() || this.account.isContentMode());
    }

    enterContentMode() {
        this.account.enterContentMode();
        this.router.navigateByUrl('library/audio/dam');
    }

    toggleMenu() {
        // add show class to navbar-collapse else remove show 
        const navBarCollapse = document.getElementById('navbarSupportedContent');

        if (navBarCollapse.classList.contains('show')) {
            navBarCollapse.classList.remove('show');
            this.mobileNavBar = false;
        } else {
            navBarCollapse.classList.add('show');
            this.mobileNavBar = true;
            document.addEventListener('click', (event) => {
                const target = event.target as HTMLElement;
                if (target.classList.contains('amp-tab')) {
                    document.getElementById('navbarSupportedContent').classList.remove('show');
                    document.removeEventListener('click', (event) => { })
                }
            });
        }
    }

    // call back function to close menu when clicked outside
    clickedOutsideNav($event) {
        console.log($event);
        if ($event.enabled && $event.visible && this.mobileNavBar) {
           this.toggleMenu();
        }
    }
}
