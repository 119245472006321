
export interface IPrivacyPolicyDataHelper {
    name: string;
    value: Array<string>;
}
interface IPrivacyPolicyDataSummaryHelper {
    name: string;
    summary: string;
}
export interface IPrivacyPolicyData {
    mainHeading: IPrivacyPolicyDataSummaryHelper;
    dataController: IPrivacyPolicyDataHelper;
    informationWeCollect: IPrivacyPolicyDataHelper;
    lawfulBasisForProcessing: IPrivacyPolicyDataHelper;
    howWeUseYourInformation: IPrivacyPolicyDataHelper;
    dataSecurity: IPrivacyPolicyDataHelper;
    thirdPartyServices: IPrivacyPolicyDataHelper;
    yourRights: IPrivacyPolicyDataHelper;
    dataTransfers: IPrivacyPolicyDataHelper;
    updatesToPrivacyPolicy: IPrivacyPolicyDataHelper;
    contactInformation: IPrivacyPolicyDataHelper;
    cookieInformation: IPrivacyPolicyDataSummaryHelper;
    typesOfCookieWeUse: IPrivacyPolicyDataHelper;
    yourCookiesChoice: IPrivacyPolicyDataHelper;
}

export const privacyPolicy: IPrivacyPolicyData = {
    mainHeading: {
        name: 'Privacy Policy',
        summary: 'This Privacy Policy outlines how Atmosphere365 complies with the General Data Protection Regulation \
        (GDPR) when collecting, processing, and protecting your personal information in the context of our media \
        website, including music and video streaming, playlist creation, subscription services, and related features'
    },
    dataController: {
        name: 'Data Controller: ',
        value: [
            'Atmosphere365 is the data controller for the information you provide to us when you create an account and use our services.'
        ]
    },
    informationWeCollect: {
        name: 'Information We Collect:',
        value: [
            '<strong>Account Information</strong>: We collect your username, email address, and password when you create an account.',
            '<strong>Usage Data</strong>: Information about your interactions with our website, such as the content you play,playlists you create, and subscription details.',
            '<strong>Payment Information</strong>: If you opt for a subscription, we collect payment details for secure transaction processing.',
            '<strong>Device and Location Data</strong>: We may collect information about the device you use and your approximate location for an improved user experience.',
        ],
    },
    lawfulBasisForProcessing: {
        name: 'Lawful Basis for Processing:',
        value: [
            'We process your personal information to provide you with the services you have requested and to comply with our legal obligations.',
            '<strong>Consent</strong>: We rely on your consent to process your personal information when you create an account and use our services.',
            '<strong>Legitimate Interest</strong>: We process your personal information to provide you with a personalized experience and to improve our services  based on your consent',
        ],
    },
    howWeUseYourInformation: {
        name: 'How We Use Your Information:',
        value: [
            'We use your information to provide you with the services you have requested and to improve your user experience.',
            '<strong>Account Management</strong>: We use your information to create and manage your account.',
            '<strong>Service Improvement</strong>: We use your information to improve our services and to develop new features.',
            '<strong>Customer Support</strong>: We use your information to respond to your requests for assistance.',
            '<strong>Marketing</strong>: We use your information to send you promotional emails about our services.',
            '<strong>Legal Compliance</strong>: We use your information to comply with applicable laws and regulations.',
            '<strong>Providing Services</strong>: Your data is processed to deliver our media streaming, playlist, and subscription services.',
            '<strong>Personalization</strong>: Information is used to personalize your experience, recommend content, and enhance our services.',
            '<strong>Communication</strong>: We may contact you regarding your account, transactions, or important updates.',
            '<strong>Analytics</strong>: Aggregated and anonymized data is used for analyzing trends, improving our website, and enhancing user experience.',
        ],
    },
    dataSecurity: {
        name: 'Data Security:',
        value: [
            'We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure.',
            'We use encryption to protect your personal information and monitor our systems for possible vulnerabilities and attacks.',
            'We restrict access to your personal information to our employees and contractors who need to know that information in order to process it for us, and who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.',
        ],
    },
    thirdPartyServices: {
        name: 'Third-Party Services:',
        value: [
            'We use third-party services to provide you with our services and to improve your user experience.',
            'We use third-party services to process payments, send emails, and analyze trends.',
            'We may share your information with third-party service providers to provide you with our services.',
            'We do not sell your personal information to third parties.',
        ],
    },
    yourRights: {
        name: 'Your Rights:',
        value: [
            'You have the right to access, update, or delete the personal information we have collected about you.',
            'You have the right to opt-out of receiving promotional emails from us.',
            'You have the right to complain to a data protection authority about our collection and use of your personal information.',
        ],
    },
    dataTransfers: {
        name: 'Data Transfers:',
        value: [
            'If your data is transferred outside the European Economic Area (EEA), appropriate safeguards are implemented to ensure its security.',
            'We may transfer your personal information to countries other than the country in which the information was originally collected.',
            'These countries may not have the same data protection laws as the country in which you provided the information.',
            'When we transfer your personal information to other countries, we will protect that information as described in this Privacy Policy.',
        ],
    },
    updatesToPrivacyPolicy: {
        name: 'Updates to Privacy Policy:',
        value: [
            'We may update this Privacy Policy from time to time to reflect changes in our practices, technologies, legal requirements, and other factors.',
            'If we do, we will update the "Effective Date" at the top of this Privacy Policy.',
            'If we make a material change to this Privacy Policy, we will notify you by email or by means of a notice on our website.',
        ],
    },
    contactInformation: {
        name: 'Contact Information:',
        value: [
            'For any questions or concerns regarding your data and privacy, contact us at info-at-atmospheremxt.com.'
        ]
    },
    cookieInformation: {
        name: 'Cookie Information:',
        summary:`This website uses cookies to enhance the user experience.
            Cookies are small text files stored on your device that allow us
            to remember your preferences and provide personalized content.
            They also help us analyze website traffic.`
    },
    typesOfCookieWeUse: {
        name: 'Types of Cookie We Use:',
        value: [
            '<strong>Essential Cookies:</strong> These cookies are necessary for the website to function properly',
            '<strong>Analytical Cookies:</strong> We use these cookies to analyze how users interact with our website, helping us improve its performance',
            '<strong>Functional Cookies:</strong> These cookies enable enhanced functionality and personalization, such as remembering your preferences',
        ]
    },
    yourCookiesChoice: {
        name: 'Your Cookies Choices:',
        value: [`You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually
            modify your browser settings to decline cookies if you prefer. However, this may prevent you from taking full
            advantage of the website's features.`,
            'By continuing to use our website without changing your browser settings, you consent to our use of cookies.',
            'For more information, please refer to our <a style="color:blue" href="/privacy-policy">Privacy Policy</a>.'
        ]
    }
}