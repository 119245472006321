<!-- This is the section that pops up when we click "see more" on the cookie banner  -->
<div id="cookies-container" style="display: none;">
    <h1>Cookies</h1>
    <div class="each-cookie">
        <i class=""><b>{{functionCookie.name | translate}}</b></i>
        <div>
            <p>{{functionCookie.message | translate }}</p>
            <span>
                <input type="checkbox" checked="checked" disabled>
            </span>
        </div>
    </div>
    <div class="each-cookie">
        <i class=""><b>{{analyticsCookies.name | translate}}</b></i>
        <div id="matomo-opt-out"></div>
    </div>
    <div>
        <a role="button" tabindex="0" class="more-cookies"
            onclick="window.customFnc('allCookiesAllowed')">{{allowCookies | translate}}</a>
        <a role="button" tabindex="0" class="more-cookies"
            onclick="window.customFnc('allCookiesRejected')">{{denyCookies | translate}}</a>
    </div>
</div>