import { environment } from "environments/environment";
import { NgcCookieConsentConfig } from "ngx-cookieconsent";




export abstract class SetupCookieConfig {
    
    public static get cookieConfig(): NgcCookieConsentConfig {
        return SetupCookieConfig._config;
    }

    private static get _config(): NgcCookieConsentConfig {
        return {
            cookie: {
                // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
                domain: environment.cookieDomain + '.atmosphere365.com' 
            },
            palette: {
                popup: {
                    background: '#202020'
                },
                button: {
                    background: '#802db5',
                    text: '#f1f1f1'
                }
            },
            type: 'opt-out',
            layout: 'my-custom-layout',
            layouts: {
                "my-custom-layout": '{{messagelink}}{{customButton}}{{compliance}}'
            },
            elements: {
                messagelink: `
                <span id="cookieconsent:desc" class="cc-message">{{message}} 
                  <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
                  <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" role="button" onclick={window.customFnc(window.cookieConfig.PrivacyDetailView)} target="_blank">{{privacyPolicyLink}}</a> and our 
                  <a aria-label="learn more about our terms of service" tabindex="2" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
                </span>
                `,
                compliance: `
                `,
                customButton: `
                <a role="button" class="cc-deny" onclick="window.customFnc('cookieDetailView')">See More</a>
                `
            },
            content: {
                message: 'By using our site, you acknowledge that you have read and understand our ',

                cookiePolicyLink: 'Cookie Policy',
                cookiePolicyHref: '#/privacy-atmosphere365',

                privacyPolicyLink: 'Privacy Policy',
                // privacyPolicyHref: environment.cookieDomain + '.atmosphere365.com' + '#/privacy-atmosphere365', // for prod/qa
                privacyPolicyHref: '#/privacy-atmosphere365', // for dev


                tosLink: 'Terms of Service',
                tosHref: '#/privacy-atmosphere365',
                header: "header",
                dismiss: "dismiss",
                // deny: "See More",
            },
            position: 'bottom',
            autoOpen: true,
        }
    }
}
