import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-tabs',
  templateUrl: './admin-tabs.component.html',
  styleUrls: ['./admin-tabs.component.scss']
})

export class AdminTabsComponent implements OnInit {

  isLoading = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.isLoading = true
  }

  isActive(routePath: string) {
      const currentRoute = this.router.url;
      return currentRoute.includes(routePath);
  }
}
