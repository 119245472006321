<app-dropdown-list #dropdown [classNames]="'dropleft d-inline'" [btnClassNames]="'btn-dropdown'" [searchable]="true"
    [buttonType]="'image'" [dropdownButtonContent]="'../../../assets/img/icons_ic_options.png'"
    [listItems]="playlistListItems" [nestableListDelegate]="this" [hideDescription]="true">
    <a *ngIf="true && (!multiSelectListMediator || multiSelectListMediator.getSelected().length == 0)"
        class="dropdown-item" (click)="open('create playlist')">{{ 'Create new with all items...' | translate }}
    </a>
    <a *ngIf="true && (multiSelectListMediator && multiSelectListMediator.getSelected().length != 0)"
        class="dropdown-item" (click)="open('create playlist')">
        {{ 'Create new with selected items...' | translate }}
    </a>
    <div class="dropdown-divider"></div>
    <p *ngIf="!multiSelectListMediator || multiSelectListMediator.getSelected().length == 0" class="dropdown-header">
        {{'Add all items to Playlist' | translate }}
    </p>
    <p *ngIf="multiSelectListMediator && multiSelectListMediator.getSelected().length != 0" class="dropdown-header">
        {{'Add selected items to Playlist' | translate }}
    </p>
</app-dropdown-list>