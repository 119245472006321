<div class="dropdown">
    <button
        [disabled]="multiSelectListMediator.getSelected().length === 0"
        class="btn-dropdown"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
    >
        <img class="options-img" src="../../../../assets/img/icons_ic_options.png" />
    </button>
    <div #dropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a (click)="openDamModal()" *ngIf="multiSelectListMediator.getSelected().length === 1" class="dropdown-item"
            >Edit Item</a
        >
        <a (click)="refreshDropbox()" class="dropdown-item">Refresh Dropbox</a>
    </div>
</div>
