import { Component, OnInit } from "@angular/core";
import { LibraryConfigService, UnSub } from 'amp-ng-library';
import { map, takeUntil } from "rxjs/operators";
import {AMPEvent, AMPEventName} from 'amp-ng-library';
import {EventBusService} from 'amp-ng-library';

@Component({
    selector: "app-notices",
    templateUrl: "./notices.component.html",
    styleUrls: ["./notices.component.scss"],
})
export class NoticesComponent extends UnSub implements OnInit {
    notice: INoticeComponent = { success: null, error: null };
    constructor(
        private eventBus: EventBusService,
        protected configService: LibraryConfigService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.eventBus
            .subscribe(AMPEventName.NOTICE_RECEIVED)
            .pipe(
                map((ev: AMPEvent) => ev.eventPayload),
                takeUntil(this.unsubscribe$)
            )
            .subscribe((payload: any) => {
                this.notice.success = payload.eventData.success;
                this.notice.error = payload.eventData.error;
                const timerId = setTimeout(() => {
                    this.notice = { success: null, error: null };
                    clearTimeout(timerId);
                }, 3000);
            });
    }
}

interface INoticeComponent {
    success: string | null;
    error: string | null;
}
