import { Component, OnInit} from '@angular/core';



@Component({
  selector: 'cp-tabs',
  templateUrl: './cp-tabs.component.html'
})

export class CPTabsComponent implements OnInit {

  isLoading = false;

  constructor() {
  }

  ngOnInit() {
    this.isLoading = true
  }

}
