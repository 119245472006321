
const IMAGE_SERVER = 'https://www.atmosphere365.com';
const IMAGE_DIRECTORY = 'images/Production';

const CLIENT_ID = '50139dc8-0a05-4fbb-a891-da113788e27a';
const CLIENT_SECRET = 'AwF/j/i0UEbta4uKwxNle7eTplMr+7n+TaFch1Y7IE842843sVCu3Z6swbBnDuM5eMcBKEqe9SpcRsytA6q+oaHFSya7NJkoAaEMTKKbCIH8eAwQo6d5MITrFXdySKqjMgs=';

const STRIPE_TEST_API_KEY = 'pk_test_B3xOvVaaIBZAFxuETYut44Tn';

const HOST = 'accountdev.atmosphere365.com';
// const HOST = 'localhost:8080';
export const environment = {
    analyticSiteId: 1,
  production: false,
    host: 'accountdev.atmosphere365.com',
   server: 'https://' + HOST, // NOTE: http for local host
   wsserver: 'wss://' + HOST + '/ampws', // NOTE: ws not wss. (For localhost only).
    // server: 'http://' + HOST, // NOTE: http for local host
    // wsserver: 'ws://' + HOST, // NOTE: ws not wss. (For localhost only).
    cookieDomain: 'accountdev',

  imageServer: IMAGE_SERVER,
  imageDirectory: IMAGE_DIRECTORY,

  clientId: CLIENT_ID,
  clientSecret: CLIENT_SECRET,

  stripeAPIKey: STRIPE_TEST_API_KEY,
  wsProtocols: ["ampwsjs", "nm2nxm3ndhkg49jgs654kdgsk578gdksgdkgs"]
};
