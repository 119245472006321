import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'reseller-tabs',
  templateUrl: './reseller-tabs.component.html'
})

export class ResellerTabsComponent implements OnInit {

  isLoading = false;

  constructor() {
  }

  ngOnInit() {
    this.isLoading = true
  }

}
