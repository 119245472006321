import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Account } from 'amp-ng-library';
import { AccountService } from 'amp-ng-library';
import { ContentItemPlays } from '../_models/contentitemplays';
import { Business } from 'amp-ng-library';
import { Order } from '../_models/order';
import { Venue } from '../_models/venue';
import { AdminProduct } from '../_models/admin-product';
import { map } from 'rxjs';
import 'rxjs';


export enum LicenceAction {
    Expire = 1,
    Renew,
    Extend,
}

@Injectable()
export class AdminService {
    private adminBasePath: String = `${environment.server}/ampapi/admin`;
    private damBasePath: String = `${environment.server}/ampapi/dam`;
    private damTagsBasePath: String = `${environment.server}/ampapi/v3/dam`;

    constructor(
        private http: HttpClient,
        private accountService: AccountService,
    ) {}

    public getHeaders(): HttpHeaders {
        let headers = null;
        if (this.accountService.token) {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                client_id: environment.clientId,
                client_secret: environment.clientSecret,
                token: this.accountService.token,
            });
        } else {
            headers = new HttpHeaders({
                'Content-Type': 'application/json',
                client_id: environment.clientId,
                client_secret: environment.clientSecret,
            });
        }

        return headers;
    }

    public fetchResellers(): Observable<Account[]> {
        let url = this.adminBasePath + '/reseller';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http
            .get(url, options)
            .pipe(
                map((response: HttpResponse<any>) => (<any>response.body).responseData),
            )
    }

    public fetchContentProviders(): Observable<Account[]> {
        let url = this.adminBasePath + '/cp/s';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http
            .get(url, options)
            .pipe(
                map((response: HttpResponse<any>) => response.body.responseData),
            )
    }

    public fetchContentItemPlays(days: number, mt: number = 1, cc: string = 'all'): Observable<ContentItemPlays[]> {
        const url = this.adminBasePath + `/reports/contentitemplays/${days}/mediatype/${mt}/country/${cc}`;
        // let options = {headers: this.getHeaders(), observe: 'response' as 'response'};
        const options = this.makeOptionsHeaderText();

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => response.body.responseData),
        )
    }

    public getInstallationEventsByContentItem(itemId: bigint, cc: string = 'all', days: number): Observable<any> {
        const url = this.adminBasePath + `/reports/${itemId}/country/${cc}/days/${days}`;
        const options = this.makeOptionsHeaderText();

        return this.http
            .get(url, options)
            .pipe(
                map((response: HttpResponse<any>) => response.body.responseData || JSON.parse(response.body).responseData),
            )
    }

    public libraryUpdated(): Observable<{}> {
        const url = this.adminBasePath + '/library/updated';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => response.body.responseData),
        )
    }

    public updateResellerDetails(business: Business): Observable<any> {
        let url = this.adminBasePath + '/reseller';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };
        return this.http.post(url, business, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public updateContentProviderDetails(business: Business): Observable<any> {
        let url = this.adminBasePath + '/cp';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };
        return this.http.post(url, business, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public fetchOrders(resellerId: number): Observable<Order[]> {
        let url = this.adminBasePath + '/order/' + resellerId;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };
        return this.http
            .get(url, options)
            .pipe(
                map((response: HttpResponse<any>) => response.body.responseData),
            )
    }

    public fetchProducts(): Observable<AdminProduct[]> {
        const url = this.damBasePath + '/product/s/false';
        const options = {
            headers: this.getHeaders(),
            observe: 'response' as 'response',
            responseType: 'text' as const,
        };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public fetchAccounts(includeTrial: boolean): Observable<Account[]> {
        let url = this.adminBasePath + '/accounts/' + includeTrial;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http
            .get(url, options)
            .pipe(
                map((response: HttpResponse<any>) => response.body.responseData),
            )
    }

    public fetchVenues(): Observable<Venue[]> {
        let url = this.adminBasePath + '/venue';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http
            .get(url, options)
            .pipe(
                map((response: HttpResponse<any>) => response.body.responseData),
            )
        }

    public assignLicence(licenceCode: string, accountId: string, installationId: number): Observable<any> {
        let url = this.adminBasePath + '/license/assign';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        let postData = {
            licenseCode: licenceCode,
            accountId: accountId,
            installationId: installationId,
        };

        return this.http.post(url, postData, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
}

    public executeLicenceAction(licenceCode: string, action: LicenceAction): Observable<any> {
        let url: string;
        switch (action) {
            case LicenceAction.Expire:
                url = this.adminBasePath + '/license/expire';
                break;
            case LicenceAction.Renew:
                url = this.adminBasePath + '/license/enablereactivation';
                break;
            default:
                url = this.adminBasePath + '/license/enablereactivation';
                break;
        }
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        let postData = {
            licenseCode: licenceCode,
        };

        return this.http.post(url, postData, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public createOrder(
        productRenditionCode: string,
        reseller: string,
        licenseCount: number,
        licenseType: number,
        licenseDuration: number,
        description: string,
    ): Observable<any> {
        let url: string = this.adminBasePath + '/order';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        let postData = {
            productRenditionCode: productRenditionCode,
            resellerName: reseller,
            licenseCount: licenseCount,
            licenseType: licenseType,
            licenseDuration: licenseDuration,
            description: description,
        };

        return this.http.post(url, postData, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public updateOrder(orderId: string, orderDescription: string): Observable<any> {
        let url: string = this.adminBasePath + '/order/update/' + orderId;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        let postData = {
            description: orderDescription,
        };

        return this.http.post(url, postData, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public updateTrialStatus(accountId: string, trial: boolean): Observable<any> {
        let url: string = this.adminBasePath + '/updatetrial';
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        let postData = {
            account: accountId,
            trial: trial,
        };

        return this.http.post(url, postData, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public fetchInstallationLicences(id: number): Observable<any> {
        let url = `${this.adminBasePath}/installation/${id}/licences`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };
        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => response.body.responseData),
        )
    }

    public fetchAllLicences(): Observable<any> {
        let url = `${environment.server}/ampapi/admin/installations`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response', responseType: 'text' as const };
        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => response.body.responseData),
        )
    }

    public enableUpdates(installationId: number, enabled: boolean) {
        let url = `${environment.server}/ampapi/admin/enableupdates/${installationId}/enabled/${enabled}`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            // let r = JSON.parse(response.text());
            return response.body.responseData;
        }),
    )
    }

    public postUpdatesConfig(concurrentClients: number, requests: number): Observable<any> {
        let url = `${environment.server}/ampapi/admin/server/config`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        let postData = {
            cdnMaxClients: concurrentClients,
            cdnRequestsPerClientRun: requests,
        };

        return this.http.post(url, postData, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public fetchCDNQueues(): Observable<any> {
        let url = `${environment.server}/ampapi/admin/server/cdn/queues`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public purgeCDNQueues(): Observable<any> {
        let url = `${environment.server}/ampapi/admin/server/cdn/queues`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.delete(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public getConfigParams(): Observable<any> {
        let url = `${environment.server}/ampapi/admin/server/config`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public getProductsForUpdates(): Observable<any> {
        let url = `${environment.server}/ampapi/admin/products/content`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    public notifyProductsUpdated(products: number[]): Observable<any> {
        let url = `${environment.server}/ampapi/admin/products/updated/${products}`;
        let options = { headers: this.getHeaders(), observe: 'response' as 'response' };

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }

    //email

    public sendEmailRequest(emailData: any): Observable<any> {
        let url = `${environment.server}/ampapi/admin/test/email/send`;
        const options = this.makeOptionsHeaderText();

        return this.http.post(url, emailData, options);
    }

    public getTags(): Observable<any> {
        const url = this.damTagsBasePath + '/tags';
        return this.executeGetRequest(url, true);
    }
    
    public getProductPlanOrders(resellerId: number = 0, productPlanId: number = 0): Observable<any> {
        const url = `${this.adminBasePath}/order/productplan/${productPlanId}/reseller/${resellerId}`;
        return this.executeGetRequest(url, true).pipe(
            map((response: any) => {
            return response;
        }),
    )
    }

    public getProductPlans(): Observable<any> {
        const url = `${this.adminBasePath}/productplan`;
        return this.executeGetRequest(url, true).pipe(
            map((response: any) => {
            return response;
        }),
    )
    }
    
    public createProductPlaneOrder(data: any): Observable<any> {
        const url = `${this.adminBasePath}/order/productplan`;
        const options = this.makeOptionsHeaderText();
        return this.http.post(url, data, options)
    }
    
    public searchProductPlanOrders(licence: string): Observable<any> {
        // /ampapi/admin/licence/order/search?licencecode=MJHF-GE99-LJ6D-ILCC
        const url = `${this.adminBasePath}/licence/order/search?licencecode=${licence}`;
        return this.executeGetRequest(url, true).pipe(
            map((response: any) => {
            return response;
        }),
    )
    }

    public updateProductPlanOrderDescription(data: any, orderId: number): Observable<any> {
        const url = `${this.adminBasePath}/order/productplan/update/${orderId}`;
        const options = this.makeOptionsHeaderText();
        return this.http.post(url, data, options)
    }

    private makeOptionsHeaderText() {
        return { headers: this.getHeaders(), observe: 'response' as 'response', responseType: 'text' as const };
    }

    private makeOptionsHeader() {
        return { headers: this.getHeaders(), observe: 'response' as 'response' };
    }

    private executeGetRequest(url: string, text: boolean): Observable<any> {
        const options = text ? this.makeOptionsHeaderText() : this.makeOptionsHeader();

        return this.http.get(url, options).pipe(
            map((response: HttpResponse<any>) => {
            return response.body.responseData;
        }),
    )
    }
}
