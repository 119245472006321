import { Component, OnInit } from "@angular/core";
import { IPrivacyPolicyData, IPrivacyPolicyDataHelper, privacyPolicy } from "../lang/en";

@Component({
    selector: "app-privacy-policy",
    templateUrl: "./privacy-policy.component.html",
    styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
    lastUpdateDate: string = "05/12/2023";
    companyName: string = "Atmosphere365";
    privacyDataObject: IPrivacyPolicyData = privacyPolicy;

    constructor() {}
    // TODO: change this to get the language or translate based on the language such that the imported privacy policy is already in the desired language
    ngOnInit(): void {}
}
