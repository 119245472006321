import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { ResellerHomeComponent } from './reseller/reseller-home/reseller-home.component';
import { CPHomeComponent } from './cp/cp-home/cp-home.component';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './miscellaneous/privacy-policy/privacy-policy.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },

    { path: 'home', component: HomeComponent },
    { path: 'resellerhome', component: ResellerHomeComponent, canActivate: [AuthGuard] },
    { path: 'cphome', component: CPHomeComponent, canActivate: [AuthGuard] },
    { path: 'privacy-atmosphere365', component: PrivacyPolicyComponent, canActivate: [AuthGuard] },
    // { path: 'schedules/detail/:contentOwner/:mediaType', component: SchedulesComponent, canActivate: [AuthGuard] },

    // LAZY LOADING MODULES - Child routes are handled in the routing module for each feature module

    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },

    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

    {
        path: 'forgotpassword',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },

    { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },

    { path: 'details', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },

    { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

    { path: 'library', loadChildren: () => import('./library-wrapper/library-wrapper.module').then(m => m.LibraryWrapperModule), },
    { path: 'playback/playlists', loadChildren: () => import('amp-ng-playlists').then(m => m.OnlineModule), },
    { path: 'playback/programmes', loadChildren: () => import('./playback/programmes/programmes.module').then(m => m.ProgrammesModule), },
    { path: 'playback/schedules', loadChildren: () => import('./playback/schedules/schedules.module').then(m => m.SchedulesModule), },

    // otherwise redirect to home
    { path: '**', redirectTo: 'account' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
