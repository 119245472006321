import { Component, OnInit} from '@angular/core';
import { AccountService } from 'amp-ng-library';

import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {


  constructor( private router: Router,
              private route: ActivatedRoute,
              private account: AccountService) {
  }


  ngOnInit()
  {
    if (this.account.isAdmin()) {
        if (this.account.isUserMode()) {
            this.router.navigateByUrl("dashboard")
        } else if (this.account.isContentMode()) {
            this.router.navigateByUrl("/library/audio");
        } else {
          this.router.navigateByUrl("admin")
        }

      return;
    }
    if (this.account.isReseller()) {
      this.router.navigateByUrl("resellerhome");
      return;
    }
    if (this.account.isContentProvider()) {
      this.router.navigateByUrl("cphome");
      return;
    }

    this.router.navigateByUrl("dashboard");
  }

}
