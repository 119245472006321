import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ContentModeWrapperComponent } from '../wrappers/content-mode-wrapper/content-mode-wrapper.component';
import { LibDropDownWrapperComponent } from '../wrappers/lib-drop-down-wrapper/lib-drop-down-wrapper.component';
import { AccountService, ToolbarPlaybackDelegate } from 'amp-ng-library';

import { AlertService } from 'amp-ng-library';
import { LibraryModalComponent } from '../shared/library-modal/library-modal.component';
import { RenditionService } from './rendition.service';
import {
    ActionPopoverController,
    AMPNgLibraryConfigType,
    EConfigType,
    LibraryConfigService,
    MediaType,
    TrackingService,
} from 'amp-ng-library';
import { StateService } from 'amp-ng-library/ampngcore/services/state.service';
import { ContentType } from 'amp-ng-library/ampngcore/enums/content-type.enum';

/**
 * extends LibraryConfigService
 * @see {@link LibraryConfigService}
 */
@Injectable({
    providedIn: 'root',
})
export class AppLibraryConfigService extends LibraryConfigService {
    toolBarProps: ToolbarWrapperParams;

    constructor() {
        super();
    }

    /**
     * This method is used to configure the library menu component.
     *
     * - At the moment the library is only configured using two modes: `ContentMode` and `DropdownMode`.
     *
     * - Based on the user's role and the current mode, either library/content mode menu component is configured.
     *
     * - The library may also be configured with these services `AlertService`, `StateService`, `DamService`
     */
    configureLibrary(
        account: AccountService,
        alert?: AlertService,
        state?: StateService,
        rendition?: RenditionService,
        tracking?: TrackingService,
    ): void {
        this._configureMenu(account);
        this.setEnvironment(
            environment.server,
            environment.clientId,
            environment.clientSecret,
            environment.imageDirectory,
            environment.imageServer,
            environment.stripeAPIKey,
            environment.wsProtocols,
            environment.wsserver,
        );
        this.setAccountInfo(
            account.accountId,
            account.isContentMode(),
            account.token,
            account.getLanguage(),
            account.getEmail(),
            account.isAdmin(),
            account.getSupportedLanguages(),
        );
        if (alert) {
            this.configureLibraryAlert(alert);
        } else {
            console.warn('alert service is not configured configured.');
        }

        if (state) {
            this.configureStateService(state);
        } else {
            console.warn('state service is not configured configured.');
        }

        if (rendition) {
            this.configureRenditionService(rendition);
        } else {
            console.warn('rendition service is not configured configured.');
        }

        if (tracking) {
            this.setTrackingService(tracking);
        } else {
            console.warn('tracking service is not configured configured.');
        }
    }

    /*** This is used by the toolbar-wrapper component, to pass input/params to the toolbar form component in the client app*/
    configureToolBar(
        contentType: ContentType,
        playbackDelegate: ToolbarPlaybackDelegate,
        mediaType: MediaType,
        folderId: number,
        popOverRef: {
            [key: string]: ActionPopoverController;
        },
    ): void {
        this.toolBarProps = {
            contentType: contentType,
            playbackDelegate: playbackDelegate,
            mediaType: mediaType,
            folderId: folderId,
            popOverRef: popOverRef,
        };
    }

    private _configureMenu(account: AccountService) {
        const isContentMode = account.isAdmin() && account.isContentMode();
        const component = isContentMode ? ContentModeWrapperComponent : LibDropDownWrapperComponent;
        const configType = isContentMode ? EConfigType.EditMode : EConfigType.NormalMode;
        const hasMultiSelect = true;
        const libraryModal = isContentMode ? null : LibraryModalComponent;
        const __config: AMPNgLibraryConfigType = {
            component: component,
            hasMultiSelect: hasMultiSelect,
            configType: configType,
        };
        if (libraryModal) {
            __config.libraryModal = libraryModal;
        }
        this.configLibraryMenu(__config);
    }

    private configureLibraryAlert(alert: AlertService): void {
        this.configAlert(alert);
    }

    private configureStateService(state: StateService): void {
        this.setStateService(state);
    }

    private configureRenditionService(renditionService: RenditionService): void {
        this.setRenditionService(renditionService);
    }
}

interface ToolbarWrapperParams {
    contentType: ContentType;
    playbackDelegate: ToolbarPlaybackDelegate;
    mediaType: MediaType;
    folderId: number;
    popOverRef: { [key: string]: ActionPopoverController };
}
