import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { BidiModule } from '@angular/cdk/bidi';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTreeModule } from '@angular/cdk/tree';
import { NgSelectModule } from '@ng-select/ng-select';
import { FooterComponent } from './footer/footer.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NoticesComponent } from './notices/notices.component';
import { AMPNGCoreModule } from 'amp-ng-library';
import { LibraryModalComponent } from './library-modal/library-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ContentModeWrapperComponent } from '../wrappers/content-mode-wrapper/content-mode-wrapper.component';
import { AmpPaginationComponent } from './amp-pagination/amp-pagination.component';
import { GenericSelectComponent } from './generic-select/generic-select.component';

@NgModule({
    imports: [
        CommonModule,
        NgbPopoverModule,
        BidiModule,
        TranslateModule,
        ReactiveFormsModule,
        CdkTreeModule,
        NgSelectModule,
        FormsModule,
        NgbPaginationModule,
        ScrollingModule,
        NgbDatepickerModule,
        NgbTooltipModule,
        AMPNGCoreModule,
        DragDropModule,
    ],
    exports: [
        FooterComponent,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbPaginationModule,
        NgbTooltipModule,
        NoticesComponent,
        LibraryModalComponent,
        ContentModeWrapperComponent,
        AmpPaginationComponent,
        GenericSelectComponent,
    ],
    declarations: [
        FooterComponent,
        NoticesComponent,
        LibraryModalComponent,
        ContentModeWrapperComponent,
        AmpPaginationComponent,
        GenericSelectComponent,
    ],
})
export class SharedModule {}
