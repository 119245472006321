import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import {
    AudioLibraryService,
    LibraryConfigService,
    ProductFilterService,
    ProductService,
    RequestJsonInterceptor,
    ResponseJsonInterceptor,
} from 'amp-ng-library';

import { AdminService } from './_services/admin.service';
import { AuthGuard } from './_guards/auth.guard';
import { AlertComponent } from './_alert/alert.component';
import { SafePipe } from './_directives/safe-pipe';
import { ResellerHomeComponent } from './reseller/reseller-home/reseller-home.component';
import { ResellerLicencesComponent } from './reseller/reseller-licences/reseller-licences.component';
import { HomeComponent } from './home/home.component';
import { AdminTabsComponent } from './admin/admin-tabs/admin-tabs.component';
import { ResellerTabsComponent } from './reseller/reseller-tabs/reseller-tabs.component';
import { CPTabsComponent } from './cp/cp-tabs/cp-tabs.component';
import { CPHomeComponent } from './cp/cp-home/cp-home.component';
import { NgbDropdownModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CPLicencesComponent } from './cp/cp-licences/cp-licences.component';
import { SharedModule } from './shared/shared.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { RenditionService } from './_services/rendition.service';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { ClientTabsComponent } from './client-tabs/client-tabs.component';
import { DamTabsComponent } from './tabs/dam-tabs/dam-tabs.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { SetupCookieConfig } from './miscellaneous/cookie.config';
import { PrivacyPolicyComponent } from './miscellaneous/privacy-policy/privacy-policy.component';
import { PrivacyHelperComponent } from './miscellaneous/privacy-policy/privacy-helper/privacy-helper.component';
import { CookieIndexHelperComponent } from './miscellaneous/cookies/cookie-index-helper/cookie-index-helper.component';
import { AppLibraryConfigService } from './_services/app-library-config.service';
import { LibDropDownWrapperComponent } from './wrappers/lib-drop-down-wrapper/lib-drop-down-wrapper.component';
import { PlaybackSharedModule, PlaylistsConfigService, ValidationService } from 'amp-ng-playlists';
import { AppPlaylistsConfigService } from './_services/app-playlists-config.service';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.cookieDomain + '.atmosphere365.com', // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
    },
    content: {
        message: 'This website uses cookies for purely functional purposes.',
        dismiss: 'Understood',
    },
    palette: {
        popup: {
            background: '#202020',
        },
        button: {
            background: '#802db5',
            text: '#f1f1f1',
        },
    },
    theme: 'classic',
    type: 'info',
    position: 'bottom',
    showLink: false,
};

@NgModule({
    imports: [
        BrowserModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgSelectModule,
        TranslateModule.forRoot(),
        NgbModule,
        NgbPopoverModule,
        NgbDropdownModule,
        SharedModule,
        ScrollingModule,
        NgcCookieConsentModule.forRoot(SetupCookieConfig.cookieConfig),
        AppRoutingModule,
        NoopAnimationsModule,
        PlaybackSharedModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        SafePipe,
        HomeComponent,
        ResellerHomeComponent,
        ResellerTabsComponent,
        ResellerLicencesComponent,
        CPHomeComponent,
        CPTabsComponent,
        CPLicencesComponent,
        ClientTabsComponent,
        AdminTabsComponent,
        DamTabsComponent,
        PrivacyPolicyComponent,
        PrivacyHelperComponent,
        CookieIndexHelperComponent,
        LibDropDownWrapperComponent,
    ],
    providers: [
        AudioLibraryService,
        AdminService,
        ProductService,
        ProductFilterService,
        ValidationService,
        AuthGuard,
        RenditionService,
        { provide: HTTP_INTERCEPTORS, useClass: ResponseJsonInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: RequestJsonInterceptor, multi: true },
        { provide: LibraryConfigService, useExisting: AppLibraryConfigService },
        { provide: PlaylistsConfigService, useExisting: AppPlaylistsConfigService },
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
