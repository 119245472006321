import {Injectable} from '@angular/core';
import {Rendition} from '../_models/rendition';

/*
1|4K4 |4K With Four 1K Movies - 4320x1920| 4320| 1920|h264 |
2|4K1 |4K With A Single Movie - 4320x1920| 4320| 1920|h264 |
3|UHD |Standard UHD 3840x2160 | 3840| 2160|h264 |
4|HD |Standard Full HD 1920x1080 | 1920| 1080|h264 |
20|M4A |MPEG 4 Audio | 0| 0|mp4 |
 */

const buildRenditions = () => {
    const renditions = [];
    renditions.push(new Rendition(
        1,
        RenditionService.RENDITION_4K4,
        '4K With Four 1K Movies - 4320x1920',
        4320,
        1920,
        'h264'
    ));
    renditions.push(new Rendition(
        2,
        RenditionService.RENDITION_4K1,
        '4K With A Single Movie - 4320x1920',
        4320,
        1920,
        'h264'
    ));

    renditions.push(new Rendition(
        3,
        RenditionService.RENDITION_UHD,
        'Standard UHD 3840x2160',
        3840,
        2160,
        'h264'
    ));

    renditions.push(new Rendition(
        4,
        RenditionService.RENDITION_HD,
        'Standard Full HD 1920x1080',
        1920,
        1080,
        'h264'
    ));

    renditions.push(new Rendition(
        20,
        RenditionService.RENDITION_M4A,
        'MPEG 4 Audio',
        0,
        0,
        'mp4'
    ));

    return renditions;
};

@Injectable()
export class RenditionService {
    public static RENDITION_4K4 = "4K4";
    public static RENDITION_4K1 = "4K1";
    public static RENDITION_UHD = "UHD";
    public static RENDITION_HD =  "HD";
    public static RENDITION_M4A = "M4A";

    renditions: Array<Rendition>;

    constructor() {
        this.renditions = buildRenditions();
    }

    getAll(): Array<Rendition> {
        return this.renditions;
    }

    get(id): Rendition {
        return this.renditions.find(rendition => rendition.id === id);
    }
}
