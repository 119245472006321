

export class Rendition {

    constructor(
        public id: number,
        public key: string,
        public name: string,
        public resolutionX: number,
        public resolutionY: number,
        public encoding: string
    ) {}

}
