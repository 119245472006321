import { Injectable, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CookieIndexHelperComponent } from './cookies/cookie-index-helper/cookie-index-helper.component';

/*** This class contains all the variables that are used in the cookie service.*/
class CookieServiceConfigVariables {
    protected static readonly MatomoConsent = 'MatomoConsent';
    protected static readonly MatomoOptOut = 'matomo-opt-out';
    protected static readonly MatomoCheckBox = 'checkbox';
    protected static readonly MatomoElementType = 'input'
    protected static readonly CookieConsentDate = 'cookieConsentDate';
    protected static readonly CookieConfig = 'cookieConfig';
    protected static readonly CookieDetailView = 'cookieDetailView';
    protected static readonly PrivacyDetailView = 'privacyDetailView';
    protected static readonly CustomFnc = 'customFnc';
    protected static readonly CookiesHtmlContainer = 'cookies-container';
    protected static readonly TogglePopUpClassName = '.cc-window';
    protected static readonly DoNotDisplay = 'none';
    protected static readonly Display = 'block';

}

@Injectable({
    providedIn: 'root'
})
export class CookieService extends CookieServiceConfigVariables {
    private checkboxElement: HTMLInputElement;
    private cookieContainerView: CookieIndexHelperComponent;

    constructor(
        private router: Router,
        private ccService: NgcCookieConsentService,
    ) {
        super();
        this.showTogglePopUp()
    }

    public loadCookieBanner(viewContainer: ViewContainerRef) {
        viewContainer.clear()
        let playlistDetailViewRef = viewContainer.createComponent(CookieIndexHelperComponent)
        this.cookieContainerView = playlistDetailViewRef.instance
        document.getElementById(CookieService.CookiesHtmlContainer).style.display = CookieService.DoNotDisplay;
        this.loadAnalyticsScript();
    }

    /**
     * If your need more case and the default `handleCookieDetailView` is sufficient, you can
     * override by implementing a callback class similar to `handleCookieDetailView` and passing it as reference
     * 
     * This method adds some variable/functions to the window object, which can be used in the cookie consent pop up
     * 
     * @param fncCallback callback function, please @see CookieService.handleCookieDetailView for an example of a callback function
     */
    public init(fncCallback?: Function) {
        window[CookieService.CustomFnc] = fncCallback !== undefined ? fncCallback : this.handleCookieDetailView.bind(this);
        window[CookieService.CookieConfig] = {
            CookieDetailView: CookieService.CookieDetailView,
            PrivacyDetailView: CookieService.PrivacyDetailView,
        };
    }

    public acceptCookies() {
        this.matomoCookConsent();
    }

    public rejectCookies() {
        this.matomoCookConsent();
    }

    /*** Creates and adds a checkbox element to a parent <div> in this case matomo analytics div. */
    public handleInputCheckBox() {
        const parentDiv = document.getElementById(CookieService.MatomoOptOut);
        if (parentDiv) {
            const checkboxElement = document.createElement('input');
            checkboxElement.type = CookieService.MatomoCheckBox;
            checkboxElement.checked = true
            checkboxElement.onclick = this.onClickCallback.bind(this);
            this.checkboxElement = checkboxElement;
            parentDiv.appendChild(checkboxElement);
        }
    }

    /**
     * Handle cookie change events, this is the default implementation, You can override or
     * extend this method by implementing your own set of functionalities and passing the
     * reference to that function on the init method
     * 
     * @param e event of type `CustomCookieCallBackTypes`
     * @returns
     * 
     * NB: To make this independent of angular consent pop up modal and/or module we can remove ccService as a
     *   dependency and pass/attach a callback function to this
     */
    private handleCookieDetailView(e?: any): void {
        switch (e) {
            case CustomCookieCallBackTypes.PrivacyDetailView:
                this.router.navigate(['/privacy-atmosphere365']);
                return;
            case CustomCookieCallBackTypes.CookieDetailView:
                this.ccService.close(false);
                document.getElementById(CookieService.CookiesHtmlContainer).style.display = CookieService.Display;
                return;
            case CustomCookieCallBackTypes.AllCookiesAllowed:
                this.acceptCookies()
                break;
            case CustomCookieCallBackTypes.AllCookiesRejected:
                this.rejectCookies()
                break;
            default:
                break;
        }
        document.getElementById(CookieService.CookiesHtmlContainer).style.display = CookieService.DoNotDisplay;
    }

    /**
     * Callback for the checkbox element.
     * @param event
     */
    private onClickCallback(e: Event) {
        this.checkboxElement = e.target as HTMLInputElement;
    }

    /*** Would either revoke or give consent to matomo analytics based on the checkbox state. */
    private matomoCookConsent(checkedValue: boolean = true) {

        if (this.checkboxElement === undefined) { return }
        this.checkboxElement.checked = checkedValue;


        if (this.checkboxElement.checked && window[CookieService.MatomoConsent] && window[CookieService.MatomoConsent].consentRevoked) {
            window[CookieService.MatomoConsent].consentGiven()
        } else {
            window[CookieService.MatomoConsent].consentRevoked()
        }
        localStorage.setItem(CookieService.CookieConsentDate, `${ this.timeStamp }`);
    }

    /**
     * Shows the cookie consent pop up if the user has not already accepted or rejected cookies.
     * will hide the consent pop up if the user has already accepted or rejected cookies for 14 days.
     */
    private showTogglePopUp(): void {
        let cookieConsentDate = localStorage.getItem(CookieService.CookieConsentDate);
        let now = new Date().getTime()

        if (!cookieConsentDate) { return }

        const cookieContainer = document.querySelector(CookieService.TogglePopUpClassName) as HTMLElement;
        if (!(parseInt(cookieConsentDate) < now) && cookieContainer) {
            cookieContainer.style.display = CookieService.DoNotDisplay;
        }
    }

    private get timeStamp(): number {
        return new Date().getTime() + 1000 * 60 * 60 * 24 * 14 // 14 days
    }

    private loadAnalyticsScript() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://analytics.atmosphere365.com/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=1';
        script.async = true;
        document.head.appendChild(script);
    }
}

export enum CustomCookieCallBackTypes {
    CookieDetailView = 'cookieDetailView',
    AllCookiesAllowed = 'allCookiesAllowed',
    AllCookiesRejected = 'allCookiesRejected',
    PrivacyDetailView = 'privacyDetailView',
}