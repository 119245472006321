<div class="footer-main page-structure-footer">
  <ul class="row">
      <li class="footer-text-main"><i class="fa fa-copyright" style="margin-right: 2px;"></i> atmosphere Media Experience Technologies Ltd. {{getYear()}} </li>
       <li class="footer-text-main" style="margin-right: 20px;"><i></i> <button class="btn footer-btn btn-primary"><a href="{{ 'amp-link' | translate }}">Download atmosphere player ©</a> </button> </li>

      <!--li>
        <div class="row" style="justify-content: space-evenly; margin-right: 1em;">
          <a class="amp-tab" routerLink="/news" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">{{ 'news' | translate }}</a>
          <a class="amp-tab" routerLink="/shop" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">{{ 'shop' | translate }}</a>
          <a class="amp-tab" routerLink="/contact" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true, applyToChildren: true}">{{ 'contact' | translate }}</a>
        </div>
      </li-->
  </ul>
</div>
