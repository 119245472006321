import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from 'amp-ng-library';


@Injectable()
export class AuthGuard  {

  constructor(private router: Router,
    public account: AccountService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem("token")) {
      if (this.account.isAdmin()) { 
        return true;
      } else if (!this.account.isAdmin() && state.url.startsWith('/admin')) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
      }
      return true;
    }
    // not logged in so redirect to login-form page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}

