<div class="container modal-main" *ngIf="name == 'create playlist'">
    <div class="popover-container-main">
        <span class="popover-title-main" style="text-transform: uppercase;">{{ 'Create new playlist' | translate }}</span>
        <form [formGroup]="CreatePlaylistForm" style="display: grid;">
            <label class="create-schedule-title-main">
                {{ 'Title:' | translate }}
                <input [ngClass]="!savingNewPlaylist ? 'text-input-box' : 'disabled'" type="text"
                    formControlName="PlaylistName" (keyup.enter)="createPlaylist()">
            </label>
            <div class="d-flex" *ngIf="savingNewPlaylist">
                <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                <span class="mt-3"> {{ 'Please Wait Saving Playlist...' | translate }}</span>
            </div>
        </form>
        <div class="row" style="justify-content: space-evenly; margin-top: 2em;">
            <button class="{{getCssClassName(1)}}" (click)="activeModal.close()"
                [disabled]="hasPlaylistName() && savingNewPlaylist">{{ 'CANCEL' | translate }}</button>
            <button class="{{getCssClassName(0)}}" (click)="createPlaylist()"
                [disabled]="!hasPlaylistName() || savingNewPlaylist">{{ 'Create' | translate }}</button>
        </div>
    </div>
</div>