<div class="d-flex __amp-pagination-container" >
    <div class="col-6">
        <span>
            Showing: {{pageSize * pageIndex }} - {{(length/pageSize)> 0 ?
            (((pageIndex + 1) * pageSize)> length ? 
                length 
                : ((pageIndex + 1)  *pageSize)) : 
            length}} of {{length}}
        </span>
    </div>
    <div class="col-6">
        <div class="float-right">
            <div class="pagination-container">
                <span>Items per page: {{pageSize}}</span>
                <div class="custom-dropdown" (click)="toggleDropdown()">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                    <div *ngIf="isDropdownOpen" class="dropdown-options" [appDynamicHostListener]="isDropdownOpen"  (clickOutsideListenerTags)="clickedOutside($event)">
                      <div *ngFor="let option of pageSizeOptions" (click)="handlePageSizeChange(option)">{{ option }}</div>
                    </div>
                </div> 
                <span>Page: {{ pageIndex + 1 }}/{{ totalPages }}</span>
                <button class="rounded-circle m-1" *ngIf="showFirstLastButtons" (click)="handlePageChange({ pageIndex: 0 })" [disabled]="pageIndex < 1">
                    <i class="fa fa-fast-backward" aria-hidden="true"></i>
                </button>
                <button class="rounded-circle m-1" (click)="handlePageChange({ pageIndex: pageIndex - 1 })" [disabled]="pageIndex === 0">
                    <i class="fa fa-angle-left fa-2x" aria-hidden="true"></i>
                </button>
                <button class="rounded-circle m-1" [disabled]="pageIndex === totalPages - 1" (click)="handlePageChange({ pageIndex: pageIndex + 1 })" [disabled]="length <= 1 || length <= pageSize">
                    <i class="fa fa-angle-right fa-2x" aria-hidden="true"></i>
                </button>
                <button class="rounded-circle m-1" *ngIf="showFirstLastButtons" (click)="handlePageChange({ pageIndex: totalPages - 1 })" [disabled]="pageIndex === totalPages - 1 || length <= 1">
                    <i class="fa fa-fast-forward" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
 
</div>
