<div class="row h-100 overflow-auto">

  <!-- <div class=""> -->
  <div class="col-12">
    <span>Total Licence Count:</span>
    <span class="float-md-none"> {{ totalLicenceCount }}</span>
  </div>
  <!-- </div> -->

  <!-- <div class=""> -->

  <div class="col-12 mt-5 amp-reseller-hd" *ngFor="let al of resellerLicences">
    <table class="col-8  table-sm  amp-text">
      <tbody>
        <tr>
          <td>{{ "client-id" | translate }}</td>
          <td>{{ al.client.accountId }}</td>
        </tr>
        <tr>
          <td>{{ "client-name" | translate }}</td>
          <td>{{ al.client.name }}</td>
        </tr>
        <tr>
          <td>{{ "client-phone" | translate }}</td>
          <td>{{ al.client.phone }}</td>
        </tr>
        <tr>
          <td>{{ "client-email" | translate }}</td>
          <td>{{ al.client.contactEmail }}</td>
        </tr>
        <tr>
          <td>{{ "installation-count" | translate }}</td>
          <td>{{ al.installations.length }}</td>
        </tr>
      </tbody>
    </table>

    <table class="col-12 table table-dark table-sm table-bordered amp-text">
      <thead class="thead-light">
        <tr>
          <th>{{ "venue-name" | translate }}</th>
          <th>{{ "installation-name" | translate }}</th>
          <th>{{ "product-name" | translate }}</th>
          <th>{{ "licence-code" | translate }}</th>
          <th>{{ "licence-status" | translate }}</th>
          <th>{{ "licence-expiry" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let licence of al.installations">
          <td>{{ licence.venueName }}</td>
          <td>{{ licence.installationName }}</td>
          <td>{{ licence.productName }}</td>
          <td>{{ licence.licenceCode }}</td>
          <td class="uppercase"><span
              [ngClass]="getLicenceStatusClass(licence)">{{ getLicenceStatus(licence) | translate }}</span></td>
          <td *ngIf="licence.expiryDate < 4000000000000">{{ licence.expiryDate  | date:'dd/MM/yyyy' }} </td>
          <td *ngIf="licence.expiryDate >= 4000000000000">{{ "Never" | translate }} </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- </div> -->