import {Component, OnInit} from '@angular/core';
import {ResellerLicences} from "amp-ng-library";

@Component({
  selector: 'reseller-home',
  templateUrl: './reseller-home.component.html'
})
export class ResellerHomeComponent implements OnInit {

  resellerLicences: ResellerLicences[];
  isLoading = false;
  installationsMap = {};


  constructor() {
  }

  ngOnInit() {
    this.isLoading = true;
  }
}
