<!-- <div class="d-flex justify-content-center align-items-center" [style]="'top: -13px;position: relative'" *ngIf="notice.success || notice.error">
    <p class="alert alert-success text-center p-0 m-0 w-100" [style.z-index]="999">{{notice}}</p>
    <p class="alert-success text-center m-0 w-100">{{notice.success}}</p>
</div> -->

<div class="d-flex float-right" [style]="'top: -13px;position: relative'" *ngIf="notice.success || notice.error">
    <p *ngIf="notice.success" class="p-0 m-0 mr-4" style="z-index: 999; color:rgb(91, 250, 91);">{{notice.success}}</p>
    <p *ngIf="notice.error" class="p-0 m-0 mr-4" style="z-index: 999; color:rgb(250, 91, 91);">{{notice.error}}</p>
</div>

<!-- mobile alert -->
<div *ngIf="configService.isMobile()">
    <div *ngIf="notice.success || notice.error" class="alert alert-dismissible fade show fixed-top" role="alert"
        [ngClass]="{'alert-success': notice.success, 'alert-danger': notice.error}">
        <strong *ngIf="notice.success">Success!</strong>
        <strong *ngIf="notice.error">Error!</strong>
        {{ notice.success || notice.error }}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>