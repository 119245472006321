import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {PlaylistsConfigService} from 'amp-ng-playlists';

type EnvironmentType = {
  server: string;
  clientId: string;
  clientSecret: string;
  imageDirectory: string;
  imageServer: string;
  stripeAPIKey: string;
  prodMode?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class AppPlaylistsConfigService extends PlaylistsConfigService {

  constructor() {
    super()
  }

  configurePlaylists(): void {
    this.setEnvironment(
        environment.server,
        environment.clientId,
        environment.clientSecret,
        environment.imageDirectory,
        environment.imageServer,
        environment.stripeAPIKey,
    );
  }



}
