import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'cp-home',
  templateUrl: './cp-home.component.html'
})
export class CPHomeComponent implements OnInit {


  isLoading = false;
  installationsMap = {};


  constructor() {
  }

  ngOnInit() {
    this.isLoading = true;
  }
}
