import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
    AMPContentItem,
    AMPRefreshDropboxRequest,
    AMPStatus,
    Artist,
    DamService,
    EditMovieModalComponent,
    LibraryComponentService,
    RefreshDropboxFailedModal,
    RefreshDropboxMode,
    RefreshDropboxMoviesModal,
    Tag,
} from 'amp-ng-library';
import { UnSub } from 'amp-ng-library';
import { cloneDeep } from 'lodash';
import { msToDurationFormat } from 'amp-ng-library';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-content-mode-wrapper',
    templateUrl: './content-mode-wrapper.component.html',
    styleUrls: ['./content-mode-wrapper.component.scss'],
})
export class ContentModeWrapperComponent extends UnSub implements OnInit {
    contentItems: Array<AMPContentItem>;
    tags: Array<Tag>;
    artists: Array<Artist>;

    constructor(
        private libraryMenuService: LibraryComponentService,
        private modalService: NgbModal,
        private damService: DamService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.contentItems = this.libraryMenuService.getContentItems();
        this.tags = this.libraryMenuService.getConteModeTagsAndArtists().tags;
        this.artists = this.libraryMenuService.getConteModeTagsAndArtists().artists;
    }

    get multiSelectListMediator() {
        return this.libraryMenuService.getMultiSelectListMediator();
    }

    openDamModal() {
        let options: NgbModalOptions = {
            size: 'lg',
            centered: true,
            modalDialogClass: 'w-75',
            backdrop: 'static',
            keyboard: false,
        };

        const modalRef = this.modalService.open(EditMovieModalComponent, options);

        const selectedIdentifier = this.multiSelectListMediator.getSelectedIdentifiers().find(identifier => {
            return identifier;
        });

        const contentItem = this.libraryMenuService.getContentItems().find(content => {
            return content.identifier === selectedIdentifier;
        });
        this.getContentItemDeep(contentItem.id).then((contentItemDeep: AMPContentItem) => {
            const contentItemCopy = cloneDeep(contentItemDeep);
            if (contentItemCopy) {
                modalRef.componentInstance.contentItem = contentItemCopy;
                modalRef.componentInstance.duration = this.getDuration(contentItemCopy);
                modalRef.componentInstance.mediaStart = this.getMediaStart(contentItemCopy);
                modalRef.componentInstance.mediaEnd = this.getMediaEnd(contentItemCopy);
                modalRef.componentInstance.tags = this.tags;
                modalRef.componentInstance.artistNames = this.artists;
            }
            modalRef.result.then(
                (updateLibrary: boolean) => {
                    if (updateLibrary) {
                        this.libraryMenuService.startUpdateLibrary();
                    }
                },
                (rejectReason: any) => {
                    // modal closed with click outside
                },
            );
        });
    }

    getContentItemDeep(id: number): Promise<AMPContentItem> {
        return this.damService.fetchContentItemById(id).toPromise();
    }

    getDuration(item: AMPContentItem) {
        return msToDurationFormat(item.duration);
    }

    getMediaStart(item: AMPContentItem) {
        return msToDurationFormat(item.mediaStart);
    }

    getMediaEnd(item: AMPContentItem) {
        return msToDurationFormat(item.mediaEnd);
    }

    refreshDropbox() {
        if (!this.isRefreshAllowed()) {
            this.showCannotRefreshDropboxModal();
        } else {
            this.showConfirmRefreshDropboxModal();
        }
    }

    showConfirmRefreshDropboxModal() {
        let options: NgbModalOptions = {
            size: 'sm',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            animation: true,
        };

        const modalRef: NgbModalRef = this.modalService.open(RefreshDropboxMoviesModal, options);
        const modalComponent: RefreshDropboxMoviesModal = modalRef.componentInstance;
        const selectedMovieNames: string[] = this.getSelectedMovieNames();
        if (!selectedMovieNames) {
            console.error('selectedMovieNames is undefined');
        } else {
            modalComponent.movieNames = selectedMovieNames;

            modalRef.result.then((confirmRefresh: boolean) => {
                if (confirmRefresh) {
                    const movieIdentifiers: bigint[] = this.multiSelectListMediator.getSelectedIdentifiers();
                    if (!movieIdentifiers) {
                        console.error('movieIdentifiers is undefined');
                    } else if (movieIdentifiers.length === 0) {
                        console.error('movieIdentifiers is empty');
                    } else {
                        const mode: RefreshDropboxMode = RefreshDropboxMode.REFRESH_DB_FILES_MODE_PRODUCTS_MOVIES;

                        const refreshDropboxRequest: AMPRefreshDropboxRequest = {
                            contentBundleId: 0,
                            mode: mode,
                            movies: movieIdentifiers,
                        };

                        this.damService
                            .refreshDropBox(refreshDropboxRequest)
                            .pipe(takeUntil(this.unsubscribe$))
                            .subscribe(
                                response => {
                                    console.log('Refresh dropbox success');
                                },
                                error => {
                                    console.log(error);
                                },
                            );
                    }
                }
            });
        }
    }

    handleConfirm() {}

    showCannotRefreshDropboxModal() {
        let options: NgbModalOptions = {
            size: 'sm',
            centered: true,
            backdrop: 'static',
            keyboard: false,
            animation: true,
        };

        const modalRef = this.modalService.open(RefreshDropboxFailedModal, options);
    }

    isRefreshAllowed(): boolean {
        const selectedItemIdentifiers: bigint[] = this.multiSelectListMediator.getSelectedIdentifiers();
        return selectedItemIdentifiers.every((identifier: bigint) => {
            const contentItem: AMPContentItem = this.contentItems.find(item => item.identifier === identifier);
            if (!contentItem) {
                console.error('contentItem is undefined');
                return false;
            }
            return contentItem.status !== AMPStatus.DRAFT;
        });
    }

    getSelectedMovieNames(): string[] {
        const selectedItemIdentifiers: bigint[] = this.multiSelectListMediator.getSelectedIdentifiers();
        return this.contentItems
            .filter((item: AMPContentItem) => selectedItemIdentifiers.includes(item.identifier))
            .map((item: AMPContentItem) => item.name);
    }
}
