<ul class="navbar-nav mr-auto">
    <!-- Only Dashboard tab will show for venue accounts -->
    <li class="nav-item px-lg-2 p-0 active">
        <a class="amp-tab" routerLink="/dashboard" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true}">{{ 'Dashboard' | translate }}</a>
    </li>
    <li *ngIf="isClient" class="nav-item  p-0">
        <a class="amp-tab" (click)="navigateToSchedules()" [class.active]="isActive('/schedules')">{{ 'schedules' | translate }}</a>
    </li>
    <li *ngIf="isClient" class="nav-item  p-0">
        <a class="amp-tab" (click)="navigateToProgrammes()" [class.active]="isActive('/programmes')">{{ 'programmes' | translate }}</a>
    </li>
    <li *ngIf="isClient" class="nav-item  p-0">
        <a class="amp-tab" (click)="navigateToPlaylists()" [class.active]="isActive('/playlists')">{{ 'playlists' | translate }}</a>
    </li>



    <li *ngIf="isClient" class="nav-item  p-0" >
        <a class="amp-tab" routerLink="/library/audio" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">{{ 'audio library' | translate }}</a>
    </li>
    <li class="nav-item  p-0" *ngIf="isClient">
        <a class="amp-tab" routerLink="/library/video" routerLinkActive="active"
           [routerLinkActiveOptions]="{exact: true }">{{ 'video library' | translate }}</a>
    </li>
</ul>

